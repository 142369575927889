import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import PreviewArticle from '../news/PreviewArticle';
import { colors, TABLET_BREAKPOINT, IPAD_BREAKPOINT, MOBILE_BREAKPOINT } from '../../util/constants';
import { SanityNewsEdge } from '../../../graphql-types';

interface Props {
  articles: Array<SanityNewsEdge>;
  removeFirst?: boolean;
  margin?: string;
}

const Wrapper = styled.div<{ margin: string; }>` 
  width: 80%;
  ${({ margin }) => margin ? `margin: ${margin};` : `margin: 0 auto`} ;
  
`;


const ArticlesGrid = styled.div`
display: grid;
grid-template-columns: repeat(3,1fr);
grid-gap: 40px;

@media (max-width:${TABLET_BREAKPOINT}px){
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
@media (max-width:${IPAD_BREAKPOINT}px){
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
}

`;

const LoadMoreButton = styled.button`
display: block;
color: ${colors.green};
background: none;
border: none;
text-decoration:underline;
margin: 40px auto;
outline:none;

&:hover{
  cursor:pointer;
}
`;

function NewsGrid(props: Props) {

  const initailSetting = {
    numberToShow: 6,
    loadMoreNumber: 6,
  };

  const [numberToShow, setNumberToShow] = useState(initailSetting.numberToShow);

  const { articles, removeFirst, margin } = props;


  return (
    <Wrapper margin={margin}>
      <ArticlesGrid>
        {articles.slice(1, numberToShow).map(article => {
          return (
            <PreviewArticle key={article.node._id} article={article.node} />
          );
        }
        )}
      </ArticlesGrid>
      {((removeFirst) && (numberToShow < articles.length)) && <LoadMoreButton
        onClick={() =>
          setNumberToShow(pre => pre + initailSetting.loadMoreNumber)
        }
      >
        Load More
        </LoadMoreButton>}
    </Wrapper>
  );
}

export default NewsGrid;
