import React, { useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";

import { A } from "../../../util/standard";
import { icons, colors } from "../../../util/constants";
import { Link } from "../../../util/types";

interface Props {
  link?: Link;
  hoverEffect?: boolean;
  margin?: string;
  isWhite?: boolean;
  width?: string;
  onClick?: () => void;
}

const Wrapper = styled.div<{ width?: string; margin?: string }>`
  ${({ width }) => width && `width: ${width};`};
  ${({ margin }) => margin && `margin: ${margin};`};
`;

const StyledLink = styled(A)<{ margin?: string; isWhite: boolean }>`
  ${({ isWhite }) =>
    isWhite
      ? `color: white; &:hover {color: ${colors.green};}`
      : `color: ${colors.green};`};
  margin-top: 30px;
  ${({ margin }) => margin && `margin: ${margin};`};
  display: block;
  min-width: 125px;
  max-width: 240px;
  width: max-content;
`;
const Arrow = styled.img<{ hoverEffect: boolean }>`
  margin-left: 10px;
  ${({ hoverEffect }) => hoverEffect && `transform: translateX(10px);`};
  transition: transform 0.2s;
`;

function ArrowLink(props: Props) {
  const { link, onClick, margin, width, isWhite } = props;
  const [hoverEffectOn, toggleHoverEffectOn] = useState(false);
  return (
    <Wrapper margin={margin} width={width} onClick={onClick}>
      <StyledLink
        isWhite={isWhite}
        margin={margin}
        hoverColor="darkBlue"
        onMouseEnter={() => toggleHoverEffectOn(true)}
        onMouseLeave={() => toggleHoverEffectOn(false)}
        key={link.linkText}
        onClick={onClick ? onClick : () => navigate(link.url)}
      >
        {link.linkText}{" "}
        <Arrow
          hoverEffect={hoverEffectOn}
          src={
            hoverEffectOn && !isWhite
              ? icons.submitArrowBlue
              : !hoverEffectOn && isWhite
              ? icons.submitArrow
              : icons.submitArrowGreen
          }
          alt="call-to-action-link-arrow"
        />
      </StyledLink>
    </Wrapper>
  );
}

export default ArrowLink;
