import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Hero from '../components/shared/Hero'
import NewsGrid from '../components/news/NewsGrid'
import DonateBlock from '../components/shared/DonateBlock'
import FeaturedArticle from '../components/news/FeaturedArticle'
import SEO from '../components/shared/Seo'
import NotFound from './404'


function news() {

  const { sanityNewsLanding, allSanityNews } = useStaticQuery(graphql`
  {
      allSanityNews(sort: { fields: articleDate, order: DESC }) {
        edges {
        node {
          articleTitle
          articleSubText
          articleDate
          articleImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                ...GatsbySanityImageFluid
              }
              title
            }
          }
          slug {
            _key
            current
          }
           _id
        }
      }
    }
    sanityNewsLanding {
      newsLandingHero {
        heroText
        heroImage {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              ...GatsbySanityImageFluid
            }
            title
          }
        }
      }
      seoBlock {
          pageDescription
          pageKeyWords  
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
    }
  }
  `)

  const { newsLandingHero } = sanityNewsLanding
  const { heroText, heroImage } = newsLandingHero

  const featuredArticle = allSanityNews.edges[0]


  return (
    <div>
      <SEO seoData={sanityNewsLanding?.seoBlock} slug='news' />
      <Hero heroText={heroText} heroImage={heroImage} />
      <FeaturedArticle article={featuredArticle} />
      <NewsGrid articles={allSanityNews.edges} removeFirst margin='80px auto 0px auto' />
      <DonateBlock />
    </div>
  )
}

export default news
