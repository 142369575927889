import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import {MOBILE_BREAKPOINT} from '../../util/constants'

import { P, H3 } from '../../util/standard'
import DonateButton from './sub/DonateButton'

const DonateBlockWrapper = styled.div`
width: 80%;
margin: 100px auto;

@media only screen and (max-width: ${MOBILE_BREAKPOINT}px){
  margin:80px auto;
}
`

const SubText = styled(P)`
text-align: center;
`

const MainText = styled(H3)`
text-align: center;
`

function DonateBlock() {

  const { sanityDonateBlock } = useStaticQuery(graphql`
  {
  sanityDonateBlock {
    subText
    mainText
    buttonLink {
      url
      linkText
    }
  }
}

  `)
  const { mainText, subText, buttonLink } = sanityDonateBlock
  return (
    <DonateBlockWrapper>
      <SubText>{subText}</SubText>
      <MainText color="black">{mainText}</MainText>
      <DonateButton buttonUrl={buttonLink.url} buttonText={buttonLink.linkText} />
    </DonateBlockWrapper>
  )
}

export default DonateBlock
