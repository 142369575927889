import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import Img from "gatsby-image";
import moment from "moment";

import { P, H2, A } from "../../util/standard";
import {
  colors,
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  IPAD_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
} from "../../util/constants";
import ArrowLink from "../shared/sub/ArrowLink";
import { SanityEvents, SanityNews } from "../../../graphql-types";

interface Props {
  article?: SanityNews;
  event?: SanityEvents;
  data?: SanityEvents | SanityNews;
}

const ArticleWrapper = styled.div`
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div<{ hoverEffect: boolean }>`
  width: 100%;
  height: 300px;

  ${({ hoverEffect }) => hoverEffect && `opacity: 0.8;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    height: 220px;
  }
  @media (max-width: ${IPAD_BREAKPOINT}px) {
    height: 300px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 220px;
  }

  transition: opacity 0.2s;
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Date = styled(P)`
  color: ${colors.darkBlue};
`;

const ArticleTitle = styled(H2)`
  font-weight: 700;
`;
const ArticleDescription = styled(P)`
  margin: 10px auto;
`;

function PreviewArticle(props: Props) {
  const { article, event } = props;
  if (article == null && event == null) return null;

  console.log({ event });

  const articleTitle = article ? article.articleTitle : event.title;
  const articleSubText = article
    ? article.articleSubText
    : event.shortDescription;
  const articleImage = article ? article.articleImage : event.thumbnailImage;
  const slug = article ? article.slug : event.slug;

  const [eventDate, setEventDate] = useState<any>();

  useEffect(() => {
    console.log({ eventDate });
  }, [eventDate]);

  useEffect(() => {
    if (article) {
      const date = moment(article.articleDate).format("MMMM Do YYYY");
      setEventDate(date);
    }
    if (event) {
      const date = moment(event?.eventDate).format("MMMM Do YYYY, h:mm a");
      setEventDate(date);
    }
  }, []);

  const [hoverEffect, toggleHoverEffect] = useState(false);

  const handleClick = (slug: string | null | undefined) => {
    navigate(article ? `/news/${slug}` : `/event/${slug}`);
  };

  return (
    <ArticleWrapper
      onMouseEnter={() => toggleHoverEffect(true)}
      onMouseLeave={() => toggleHoverEffect(false)}
      onClick={() => handleClick(slug?.current)}
    >
      <ImageWrapper hoverEffect={hoverEffect}>
        <StyledImg
          fluid={articleImage?.asset?.fluid}
          alt={articleImage?.asset?.title ?? "article-preview-image"}
          imageStyle={{ objectFit: "contain" }}
        />
      </ImageWrapper>
      <Date>{eventDate}</Date>
      <ArticleTitle>{articleTitle}</ArticleTitle>
      <ArticleDescription>{articleSubText}</ArticleDescription>
      <ArrowLink link={{ linkText: "Read More", url: `#` }} />
    </ArticleWrapper>
  );
}

export default PreviewArticle;
