import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Img from 'gatsby-image'

import { Container, H2, P } from "../../util/standard"
import { colors, icons, TABLET_BREAKPOINT, IPAD_PRO_BREAKPOINT } from '../../util/constants'
import ArrowLink from '../shared/sub/ArrowLink'
import { SanityNewsEdge } from '../../../graphql-types'

interface Props {
  article: SanityNewsEdge;
}

const FeaturedArticleWrapper = styled(Container)`
  flex-wrap: wrap;
  width: 80%;
  margin: 80px auto;
`

const StyledH2 = styled(H2)`
  margin: 0 auto;
`

const StyledP = styled(P)`
  margin: 20px auto;
`

const ImageWrapper = styled.div<{ hoverEffect?: boolean; }>`
width: 60%;
height: 400px;
position: relative;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;    

  }
  ${({ hoverEffect }) => hoverEffect && `
    opacity: 0.8;
  `}
  transition: opacity 0.2s;
  &:hover {
    cursor: pointer;
  }
`

const StyledImg = styled(Img)`
width: 100%;
height: 100%;
`

const TextWrapper = styled.div`
width: 40%;
max-height: 400px;
padding: 70px;
box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    padding: 50px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;    
  }
`

const Date = styled(P)`
  color:white;
  background-color: ${colors.red};
  position:absolute;
  bottom: -15px;
  padding: 10px 15px;

`

const Arrow = styled.img<{ hoverEffect: boolean; }>`
  color:white;
  background-color: ${colors.green};
  position:absolute;
  width: 10%;
  min-width: 60px;
  height: 10%;
  bottom: 0px;
  left: 120px;
  padding: 10px 15px;

  ${({ hoverEffect }) => hoverEffect && `background-color: ${colors.darkBlue}`};
  transition: background-color 0.2s;
`

const dateConvert = (date: string) => {
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
  const processDate = date.split('-')
  const month = Number(processDate[1])
  const convertedMonth = processDate[2] + " " + months[month - 1] + " " + processDate[0]
  return convertedMonth
}

const handleClick = (slug: string | null | undefined) => {
  navigate(`/news/${slug}`);
}

function FeaturedArticle(props: Props) {

  const { article } = props
  const [hoverEffect, toggleHoverEffect] = useState(false)
  const { articleTitle, articleSubText, articleDate, articleImage, slug } = article.node

  return (
    <FeaturedArticleWrapper onMouseEnter={() => toggleHoverEffect(true)} onMouseLeave={() => toggleHoverEffect(false)} onClick={() => navigate(slug?.current)}>
      <ImageWrapper hoverEffect={hoverEffect}>
        <StyledImg fluid={articleImage?.asset?.fluid} alt={articleImage?.asset.title ?? 'article-preview-image'} />
        <Date color="black">{dateConvert(articleDate)}</Date>
        <Arrow hoverEffect={hoverEffect} src={icons.submitArrow} onClick={() => handleClick(slug?.current)} alt='featured-article-link-arrow' />
      </ImageWrapper>
      <TextWrapper>
        <StyledH2 color={colors.darkBlue} fontWeight="bold">{articleTitle}</StyledH2>
        <StyledP color="black">{articleSubText}</StyledP>
        <ArrowLink link={{ linkText: "Read More" }}></ArrowLink>
      </TextWrapper>
    </FeaturedArticleWrapper>
  )
}

export default FeaturedArticle
